<template>
  <div class="main-box">
    <header class="header">
      <div>
        <div class="box wendu">
          <img src="../../../src/assets/wendu.png" />
          <div class="r">
            <span>温度状态</span>
            <div :class="sensors.temperatureStatus || 'active'">{{sensors.temperatureStatus == 1 ? '异常' : sensors.temperatureStatus == 2 ? "未获取" : "正常" }}</div>
          </div>
        </div>
      </div>
      <div>
        <div class="box">
          <img src="../../../src/assets/shidu.png" />
          <div class="r">
            <span>湿度状态</span>
            <div :class="sensors.humidityStatus || 'active'">{{sensors.humidityStatus == 1 ? '异常' : sensors.humidityStatus == 2 ? "未获取" : "正常"}}</div>
          </div>
        </div>
      </div>
      <div>
        <div class="box">
          <img src="../../../src/assets/yanwu.png" />
          <div class="r">
            <span>烟雾浓度状态</span>
            <div :class="sensors.smokeStatus || 'active'">{{sensors.smokeStatus == 1 ? '异常' : sensors.smokeStatus == 2 ? "未获取" : "正常"}}</div>
          </div>
        </div>
      </div>
      <div>
        <div class="box">
          <img src="../../../src/assets/shuiqin.png" />
          <div class="r">
            <span>水浸状态</span>
            <div :class="sensors.water || 'active'">{{sensors.water== 1 ? '异常' : sensors.water == 2 ? "未获取" : "正常" }}</div>
          </div>
        </div>
      </div>
      <div>
        <div class="box">
          <img src="../../../src/assets/minghuo.png" />
          <div class="r">
            <span>明火状态</span>
            <div :class="sensors.fire || 'active'">{{sensors.fire == 1 ? '异常' : sensors.fire == 2 ? "未获取" : "正常"}}</div>
          </div>
        </div>
      </div>
    </header>

    <div class="mid">
      <div class="inbox">
        <div>
          <div class="hed">
            <img src="../../../src/assets/xian.png">
            <span class="text">CUP使用情况</span>
            <div id="cup" class="chart"></div>
          </div>
        </div>
        <div>
          <div class="hed">
            <img src="../../../src/assets/xian.png">
            <span class="text">内存使用情况</span>
            <div id="neicun" class="chart"></div>
          </div>
        </div>
      </div>

    </div>

    <div class="bom">
      <div class="bombox">
        <div>
          <div class="hed">
            <img src="../../../src/assets/xian.png">
            <span class="text">实时温度数据</span>
            <div id="wendu" class="chart"></div>
          </div>
        </div>

        <div>
          <div class="hed">
            <img src="../../../src/assets/xian.png">
            <span class="text">实时湿度数据</span>
            <div id="shidu" class="chart"></div>
          </div>
        </div>

        <div>
          <div class="hed">
            <img src="../../../src/assets/xian.png">
            <span class="text">实时烟雾浓度</span>
            <div id="yanwu" class="chart"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="viode" @click="showhideViode" v-show="!isPanel">
      <el-icon color="#248975"><d-arrow-left /></el-icon>
      <span>机房监控</span>
    </div>
      <div class="viode-panel" v-show="isPanel">
        <div class="hed">机房监控</div>
        <div class="viode-box">
          <div v-for="(item,index) in cameraList" :key="index">
            <iframe style="width:100%;height:100%;object-fit: cover" frameborder="0" :src="item.serial"></iframe>
          </div>
        </div>
        <div class="tip" @click="isPanel = false">
          <span> > </span>
        </div>
      </div>
  </div>
</template>

<script>
import { ref, toRefs, reactive, onMounted, onBeforeUnmount } from 'vue';
import { getSensor, getCpu, sensorBasic, getMemory, getUserCamera } from "@/api/motorroom/centre";
import * as echarts from 'echarts';
import { getNowTime } from "../../util/date";
export default {
  setup(){
    let isPanel = ref(false);

    function showhideViode(){
      isPanel.value = true;
    }

    onBeforeUnmount(()=>{
      clearInterval(state.timer);
      clearInterval(state.timer2);
    })
    onMounted(()=>{
      getUserCamera().then(res=>{
        state.cameraList = res.data.data.slice(0,2);
      })
      ncChart();
      cupChart();
      wdChart();
      sdChart();
      ywChart();
      getCpu().then(res=>{
        cupChart(res.data.data)
      })
      //内存图表数据
      getMemory().then(res=>{
        ncChart(res.data.data)
      })
      getSensor().then(res=>{
        state.sensors = res.data.data;
        wdChart(res.data.data.temperature);
        sdChart(res.data.data.humidity);
        ywChart(res.data.data.smoke)
      });

      sensorBasic().then(res=>{
        state.timer = setInterval(()=>{
          //cup图表数据
          getCpu().then(res=>{
            cupChart(res.data.data)
          })
          //内存图表数据
          getMemory().then(res=>{
            ncChart(res.data.data)
          })
        },res.data.data.timingOne);

        state.timer2 = setInterval(()=>{
          //温度 湿度 烟雾
          getSensor().then(res=>{
            state.sensors = res.data.data;
            wdChart(res.data.data.temperature);
            sdChart(res.data.data.humidity);
            ywChart(res.data.data.smoke)
          });
        },res.data.data.timingTwo);
      })

      function cupChart(data = []){
        let chartDom = document.getElementById('cup');
        let myChart = echarts.init(chartDom);
        if(state.cupData.length > 50) state.cupData.shift();
        state.cupData.push({
          name: getNowTime(),
          value: [getNowTime(),data]
        });
        let option = {
          grid: {
            top: "10%",
            right: "10%",
            bottom: "15%",
            left: "10%"
          },
          tooltip: {
            trigger: 'axis',
            formatter: function (params) {
              let param = params[0];
              return `${param.value[0]}:${param.value[1]}%`;
            },
            axisPointer: {
              animation: false
            }
          },
          xAxis: {
            show : true,
            type: 'category',
            splitLine: {
              show: false
            },
            axisLine:{
              lineStyle:{
                color:'#4b9c8c',
                width:1,
              }
            },
          },
          yAxis: {
            show : true,
            max: 100,
            min: 0,
            interval: 20,
            type: 'value',
            boundaryGap: [0, '100%'],
            axisLabel: {
              show: true,
              interval: 'auto',
              formatter: '{value} %'
            },
            axisLine:{
              show:true,
              lineStyle:{
                color:'#4b9c8c',
                width:1,
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#082e2a",
                type: "solid"
              }
            }
          },
          series: [
            {
              type: 'line',
              showSymbol: false,
              data: state.cupData,
              symbolSize:8,
              normal:{
                show: true,
              },
              lineStyle: {
                color: "#e1d00b"
              },
              itemStyle: {
                color: "#e1d00b",
                borderType: "solid"
              },
            }
          ]
        };
        option && myChart.setOption(option);
      }

      function ncChart(data = []){
        let chartDom = document.getElementById('neicun');
        let myChart = echarts.init(chartDom);
        if(state.ncData.length > 50) state.ncData.shift();
        state.ncData.push({
          name: getNowTime(),
          value: [getNowTime(),data]
        });
        let option = {
          grid: {
            top: "10%",
            right: "10%",
            bottom: "15%",
            left: "10%"
          },
          tooltip: {
            trigger: 'axis',
            formatter: function (params) {
              let param = params[0];
              return `${param.value[0]}:${param.value[1]}%`;
            },
            axisPointer: {
              animation: false
            }
          },
          xAxis: {
            show : true,
            type: 'category',
            splitLine: {
              show: false
            },
            axisLine:{
              lineStyle:{
                color:'#4b9c8c',
                width:1,
              }
            },
          },
          yAxis: {
            show : true,
            max: 100,
            min: 0,
            interval: 20,
            type: 'value',
            boundaryGap: [0, '100%'],
            axisLabel: {
              show: true,
              interval: 'auto',
              formatter: '{value} %'
            },
            axisLine:{
              show:true,
              lineStyle:{
                color:'#4b9c8c',
                width:1,
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#082e2a",
                type: "solid"
              }
            }
          },
          series: [
            {
              type: 'line',
              showSymbol: false,
              data: state.ncData,
              symbolSize:8,
              normal:{
                show: true,
              },
              lineStyle: {
                color: "#e1d00b"
              },
              itemStyle: {
                color: "#e1d00b",
                borderType: "solid"
              },
            }
          ]
        };
        option && myChart.setOption(option);
      }

      function wdChart(data = []){
        let chartDom = document.getElementById('wendu');
        let myChart = echarts.init(chartDom);
        if(state.wdData.length > 50) state.wdData.shift();
        state.wdData.push({
          name: getNowTime(),
          value: [getNowTime(),data]
        });
        let option = {
          grid: {
            top: "10%",
            right: "10%",
            bottom: "15%",
            left: "10%"
          },
          tooltip: {
            trigger: 'axis',
            formatter: function (params) {
              let param = params[0];
              return `${param.value[0]}:${param.value[1]}℃`;
            },
            axisPointer: {
              animation: false
            }
          },
          xAxis: {
            show : true,
            type: 'category',
            splitLine: {
              show: false
            },
            axisLine:{
              lineStyle:{
                color:'#4b9c8c',
                width:1,
              }
            },
          },
          yAxis: {
            show : true,
            type: 'value',
            max: '50',
            min: '-20',
            interval:10,
            boundaryGap: [0, '100%'],
            axisLabel: {
              show: true,
              interval: 'auto',
              formatter: '{value} ℃'
            },
            axisLine:{
              show:true,
              lineStyle:{
                color:'#4b9c8c',
                width:1,
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#082e2a",
                type: "solid"
              }
            }
          },
          series: [
            {
              type: 'line',
              showSymbol: false,
              data: state.wdData,
              symbolSize:8,
              normal:{
                show: true,
              },
              lineStyle: {
                color: "#20f120"
              },
              itemStyle: {
                color: "#e1d00b",
                borderType: "solid"
              },
            }
          ]
        };
        option && myChart.setOption(option);
      }

      function sdChart(data = []){
        let chartDom = document.getElementById('shidu');
        let myChart = echarts.init(chartDom);
        if(state.sdData.length > 50) state.sdData.shift();
        state.sdData.push({
          name: getNowTime(),
          value: [getNowTime(),data]
        });
        let option = {
          grid: {
            top: "10%",
            right: "10%",
            bottom: "15%",
            left: "10%"
          },
          tooltip: {
            trigger: 'axis',
            formatter: function (params) {
              let param = params[0];
              return `${param.value[0]}:${param.value[1]}%`;
            },
            axisPointer: {
              animation: false
            }
          },
          xAxis: {
            show : true,
            type: 'category',
            splitLine: {
              show: false
            },
            axisLine:{
              lineStyle:{
                color:'#4b9c8c',
                width:1,
              }
            },
          },
          yAxis: {
            show : true,
            max: 100,
            min: 0,
            interval: 20,
            type: 'value',
            boundaryGap: [0, '100%'],
            axisLabel: {
              show: true,
            },
            axisLine:{
              show:true,
              lineStyle:{
                color:'#4b9c8c',
                width:1,
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#082e2a",
                type: "solid"
              }
            }
          },
          series: [
            {
              type: 'line',
              showSymbol: false,
              data: state.sdData,
              symbolSize:8,
              normal:{
                show: true,
              },
              lineStyle: {
                color: "#20f120"
              },
              itemStyle: {
                color: "#e1d00b",
                borderType: "solid"
              },
            }
          ]
        };
        option && myChart.setOption(option);
      }

      function ywChart(data = []){
        let chartDom = document.getElementById('yanwu');
        let myChart = echarts.init(chartDom);
        if(state.ywData.length > 50) state.ywData.shift();
        state.ywData.push({
          name: getNowTime(),
          value: [getNowTime(),data]
        });
        let option = {
          grid: {
            top: "10%",
            right: "10%",
            bottom: "15%",
            left: "10%"
          },
          tooltip: {
            trigger: 'axis',
            formatter: function (params) {
              let param = params[0];
              return `${param.value[0]}:${param.value[1]}PPM`;
            },
            axisPointer: {
              animation: false
            }
          },
          xAxis: {
            show : true,
            type: 'category',
            splitLine: {
              show: false
            },
            axisLine:{
              lineStyle:{
                color:'#4b9c8c',
                width:1,
              }
            },
          },
          yAxis: {
            show : true,
            max: 10000,
            min: 0,
            interval: 2000,
            type: 'value',
            boundaryGap: [0, '100%'],
            axisLabel: {
              show: true,
            },
            axisLine:{
              show:true,
              lineStyle:{
                color:'#4b9c8c',
                width:1,
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#082e2a",
                type: "solid"
              }
            }
          },
          series: [
            {
              type: 'line',
              showSymbol: false,
              data: state.ywData,
              symbolSize:8,
              normal:{
                show: true,
              },
              lineStyle: {
                color: "#20f120"
              },
              itemStyle: {
                color: "#e1d00b",
                borderType: "solid"
              },
            }
          ]
        };
        option && myChart.setOption(option);
      }
    })
    let state = reactive({
      cameraList:[],
      sensors:{},
      cupData:[],
      ncData:[],
      wdData:[],
      sdData:[],
      ywData:[],
      timer:null,
      timer2:null
    })
    return{
      showhideViode,
      isPanel,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
.main-box{
  width: 100%;
  .viode{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    right: 0;
    font-size: 18px;
    background: #0a2b28;
    border: 1px solid #146f57;
    border-right: none;
    padding: 10px 5px 10px 5px;
    cursor: pointer;
    z-index: 9;
    transform: translate(0,-50%);
    span{
      writing-mode:vertical-lr;
      color: #248975;
    }
  }
  .viode-panel{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0,-50%);
    width: 550px;
    height: 610px;
    background: #0a2b28;
    border: #178164 1px solid;
    .viode-box{
      height: calc(100% - 74px);
      width: 480px;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 230px);
      grid-row-gap: 40px;
      >div{

      }
    }
    .hed{
      font-size: 24px;
      font-family: Source Han Sans SC;
      font-weight: 300;
      color: #32B89C;
      line-height: 36px;
      padding: 20px 0 20px 40px;
      text-align: left;
    }
    .tip{
      position: absolute;
      top: 50%;
      transform: translate(0,-50%);
      left: -20px;
      width: 20px;
      border: #178164 1px solid;
      height: 80px;
      background: #0a2b28;
      span{
        color: #178164;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        cursor: pointer;
      }
    }
  }
  .header{
    display: grid;
    grid-template-columns:repeat(5,1fr);
    grid-template-rows: 120px;
    grid-column-gap:10px;
    >div{
      background: #0A2B28;
      border: 1px solid #178164;
      display: flex;
      align-items: center;
      justify-content: center;
      .box{
        width: 264px;
        height: 72px;
        display: flex;
        align-items: center;
        .r{
          margin-left: 20px;
          font-family: Source Han Sans SC;
          >span{
            font-size: 14px;
            font-weight: 300;
            color: #32B89C;
            line-height: 32px;
          }
          >div{
            font-size: 22px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #D9522E;
            line-height: 36px;
            &.active{
              color: #00FFC6;
            }
          }
        }
      }
    }
  }
  .mid{
    margin-top: 10px;
    width: 100%;
    .inbox{
      display: grid;
      grid-template-columns:repeat(2,1fr);
      grid-template-rows: 300px;
      background: #0a2b28;
      >div{
        margin: 5px;
        background: #071d1b;
        .hed{
          position: relative;
          width: 100%;
          height: 50px;
          img{
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
          }
          .text{
            position: absolute;
            top: 5px;
            left: 40px;
            font-size: 20px;
            color:#299a83
          }
          .chart{
            position: absolute;
            top: 50px;
            width: 100%;
            height: calc(300px - 60px);
          }
        }
      }
    }
  }

  .bom{
    margin-top: 10px;
    width: 100%;
    .bombox{
      display: grid;
      grid-template-columns:repeat(3,1fr);
      grid-template-rows: 300px;
      background: #0a2b28;
      >div{
        margin: 5px;
        background: #071d1b;
        .hed{
          position: relative;
          width: 100%;
          height: 50px;
          img{
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
          }
          .text{
            position: absolute;
            top: 5px;
            left: 40px;
            font-size: 20px;
            color:#299a83
          }
          .chart{
            position: absolute;
            top: 50px;
            width: 100%;
            height: calc(300px - 60px);
          }
        }
      }
    }
  }
}
</style>
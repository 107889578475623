import axios from "@/api/axios"; // 引用axios

export const getSensor = (params) => {
    return axios({
        url: "/api/big/screen/getSensor",
        method: "post",
        params: params,
    });
};

export const getCpu = (params) => {
    return axios({
        url: "/api/sensor/getCpu",
        method: "post",
        params: params,
    });
};

export const sensorBasic = (params) => {
    return axios({
        url: "/api/sensorBasic/getNewSensorBasic",
        method: "post",
        params: params,
    });
};

export const getMemory = (params) => {
    return axios({
        url: "/api/sensor/getMemory",
        method: "post",
        params: params,
    });
};

export const getUserCamera = (params) => {
    return axios({
        url: "/api/cameraRoom/getUserCameraList",
        method: "post",
        params: params,
    });
};
